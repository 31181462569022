@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600&display=swap);
* {
  font-family: "Nunito", sans-serif;
  box-sizing: border-box;
}

@font-face {
  font-family: "Avenir Black";
  src: local("AvenirBlack"), url(/static/media/AvenirBlack.d10ab385.ttf) format("truetype");
  font-weight: normal;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  height: 100%;
}

h1 {
  font-size: 22px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: #00536f;
}

.title {
  font-family: "Avenir Black";
  font-size: 4rem;
  margin-bottom: 2rem;
}

.feature-title {
  font-family: "Avenir Black";
  font-size: 1.5rem;
  margin: 1rem 0;
}

.header-text {
  font-family: "Nunito", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  color: #707070;
}

p {
  font-size: 16px;
  color: #00536f;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin: 15px 0;
}

ul li img {
  margin: 0 10px;
}

.primary-info {
  color: #00536f;
}

.secundary-info {
  color: #00536f80; /* 50% opacity through color, using separate opacity is incompatible with backdrop filter! */
}

.btn-lets {
  height: 62px;
  line-height: 54px;
  margin: 6px 5px;
  border-radius: 2px;
}

.form-control {
  background: #f3f8f9 !important;
  color: #00536f !important;
}

.new-line {
  white-space: pre-line;
}

.iconWrapper {
  width: 40px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
}

.iconWrapperSmall {
  display: block;
  width: 20px;
  height: 100%;
  margin-right: 20px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 767px) {
  .lets-logo {
    width: 130px;
    margin: 4rem 0;
  }

  .container {
    padding-bottom: 11rem;
  }

  .subtitle {
    text-align: center;
    font-family: "Avenir Black";
    font-size: 1rem;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .lets-logo {
    width: 130px;
    margin: 4rem;
  }

  wrapper .row {
    margin-left: 6rem;
  }

  .subtitle {
    font-family: "Avenir Black";
    font-size: 1.5rem;
  }
}

